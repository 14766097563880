import React from "react"
import styled from "styled-components"
import ReactDatePicker from "shared/components/DatePicker"
import { string, object } from "prop-types"
import Label from "mill/components/Label"
import FormGroup from "mill/components/FormGroup"

const Error = styled.small`
  margin-top: 0.3rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.2em;
  color: #d32f2f;
`

const HelpText = styled.small`
  margin-top: 0.4rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.3em;
  opacity: 0.9;
`

export const FormikDatePicker = ({
  field,
  form: { errors, setFieldValue },
  helpText,
  ...props
}) => {
  const fieldHasErrors = errors[field.name]
  const handleChange = date => {
    date && setFieldValue(field.name, date)
  }

  const renderInput = () => {
    return (
      <div>
        {props.label && (
          <Label hasError={fieldHasErrors} title={props.label}>
            {props.description}
          </Label>
        )}

        <ReactDatePicker
          data-qa={props["data-qa"]}
          utcOffset={props.utcOffset}
          minDate={props.minDate}
          excludeDates={props.excludeDates}
          onChange={handleChange}
          selected={field.value}
          withIcon={props.withIcon}
        />

        {fieldHasErrors && <Error>{errors[field.name]}</Error>}
        {helpText && <HelpText>{helpText}</HelpText>}
      </div>
    )
  }

  return props.withFieldset ? (
    <FormGroup>{renderInput()}</FormGroup>
  ) : (
    renderInput()
  )
}

const DatePicker = props => {
  const handleChange = date => {
    date && props.input.onChange(date.format("YYYY-MM-DD"))
  }

  const value = props.input.value ? props.input.value : new Date()
  const theDate = props.input.value ? Date.parse(value) : null

  const renderContextualHelp = () => {
    const {
      meta: { touched, error },
      helpText
    } = props
    return touched && error ? (
      <Error>{error}</Error>
    ) : helpText ? (
      <HelpText>{helpText}</HelpText>
    ) : null
  }

  const renderInput = () => {
    const {
      meta: { touched, error }
    } = props
    return (
      <div className={styles.fieldContainer}>
        {props.label && (
          <Label hasError={touched && error} title={props.label}>
            {props.description}
          </Label>
        )}

        <ReactDatePicker
          data-qa={props["data-qa"]}
          utcOffset={props.utcOffset}
          minDate={props.minDate}
          excludeDates={props.excludeDates}
          onChange={handleChange}
          selected={theDate}
          withIcon={props.withIcon}
        />

        {renderContextualHelp()}
      </div>
    )
  }

  return props.withFieldset ? (
    <FormGroup>{renderInput()}</FormGroup>
  ) : (
    renderInput()
  )
}

DatePicker.propTypes = {
  input: object.isRequired,
  label: string
}

export default DatePicker
