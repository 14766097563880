import styled from "styled-components"
import colors from "shared/utils/colors"

export const FormGroupHeading = styled.h2`
  color: #535353;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
`

export const FieldGroup = styled.div`
  margin-bottom: 1.5rem;
  font-weight: 500;

  label {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`

export const CampaignDates = styled.div`
  background: #f6f6f6;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 9%);
  padding: 3rem;
  margin: 4rem 0 4rem;

  p > em {
    font-size: 1.3rem;
  }
`

export const CampaignPhaseInfo = styled.p`
  ${props => props.noMarginBottom && `margin-bottom: 0`};

  strong {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: ${colors.grayWhisper};
    ${props => props.warmup && `text-decoration-color: ${props.theme.colors.secondary};`}
    ${props => props.active && `text-decoration-color: ${props.theme.colors.primary};`}
    ${props => props.cooldown && `text-decoration-color: ${props.theme.colors.quinary};`}
  }
`
