import styled from "styled-components"
import { md } from "mill/utils/breakpoints"

export const Container = styled.div`
  p {
    font-size: ${props => (props.largerFont ? "1.6rem" : "1.4rem")};
  }
`

export const Event = styled.div`
  display: grid;
  grid-template-columns: 13rem 1fr;

  p {
    ${({ paddingBottom }) =>
      paddingBottom &&
      `
      padding-bottom: 0.8rem;
      `}
  }

  > div {
    &:first-of-type {
      border-right: 2px solid
        ${props => props.theme.colors[props.color] || "#e9e9e9"};
    }
    &:last-of-type {
      ${({ elapsed }) => elapsed && `text-decoration: line-through`};
      border-right: none;
    }
  }

  @media ${md} {
    grid-template-columns: 15rem 1fr;
  }
`
