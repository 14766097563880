import React from "react"
import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

function Tooltip({ children, hoverText, flex }) {
  return (
    <TooltipContainer flex={flex}>
      {children}
      <TooltipText>{hoverText}</TooltipText>
    </TooltipContainer>
  )
}

export default Tooltip

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  ${props => props.flex && `display: flex;`}
  &:hover {
    span {
      visibility: visible;
      opacity: 1;
    }
  }
`

const TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 10px;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  z-index: ${zIndex.tooltip};
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`
