import React from "react"
import ReactDatePicker from "react-datepicker"
import styled from "styled-components"
import { bodyFont } from "mill/utils/settings"
import { boxSizes } from "shared/utils/boxSizes"
import zIndex from "shared/utils/zIndexLevels"

const Container = styled.div`
  .react-datepicker {
    background-color: #fbfbfb;
    color: #354a50;
    display: inline-block;
    position: relative;
    padding: ${boxSizes.mobile.small} ${boxSizes.mobile.small};
  }

  .react-datepicker-popper {
    z-index: ${zIndex.base + 1};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__input-container {
    position: relative;

    input {
      width: 100%;
      padding-left: 5rem;
      position: relative;
      min-width: 20rem;
      border-radius: 3px;
      border-color: rgb(185, 185, 185);
      border-width: 1px;
      border-style: solid;
      padding: 0.8rem 1.5rem 0.8rem 1.5rem;
      font-size: 1.6rem;
      font-family: ${bodyFont};
      line-height: 2.2rem;
      transition: all 0.2s ease-out;
      color: rgb(142, 144, 145);

      &:focus {
        outline: none;
        border-color: ${props => props.theme.colors.primary};
        transition: all 0.2s ease-in;
      }
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    width: 3.6rem;
    line-height: 3.6rem;
    text-align: center;
    margin: 0.166rem;
    cursor: pointer;
    font-size: 1.5rem;
  }

  .react-datepicker__day-names {
    font-size: 1.3rem;
  }

  .react-datepicker__day {
    &:hover {
      background: #f6f6f6;
    }
  }

  .react-datepicker__day--selected {
    background: ${props => props.theme.colors.primary};
    color: white;
    border-radius: 3px;
    &:hover {
      background: ${props => props.theme.colors.primary};
    }
  }

  .react-datepicker__day--disabled {
    background: white;
    color: #cacccd;
    &:hover {
      background-color: white;
    }
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold;
  }

  .react-datepicker__current-month {
    text-align: center;
    font-weight: 600;
  }

  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: ${zIndex.base + 1};
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__navigation--previous {
    left: 2px;
  }

  .react-datepicker__navigation--next {
    right: 2px;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 85px;
  }

  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__navigation--years-previous {
    top: 4px;
  }
  .react-datepicker__navigation--years-upcoming {
    top: -4px;
  }
  .react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6;
  }

  .react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
  }
  .react-datepicker__navigation-icon--next {
    left: -2px;
  }
  .react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
  }
  .react-datepicker__navigation-icon--previous {
    right: -2px;
  }
  .react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 16px;
    width: 9px;
  }

  ${props =>
    props.withIcon &&
    `
      .react-datepicker__input-container {
        input { 
          padding: 0.8rem 1.5rem 0.8rem 6rem;
        }
        &:before {
          content: "\f013";
          pointer-events: none;
          position: absolute;
          border-radius: 3px 0 0 3px;
          color: white;
          top: 0rem;
          left: 0rem;
          width: 4.5rem;
          height: 4rem;
          z-index: ${zIndex.base + 1};
          background-color: ${props.theme.colors.primary};
          font: normal normal normal 14px/1 Jupiter;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
  `}
`

const DatePicker = props => {
  return (
    <Container withIcon={props.withIcon} data-qa={props["data-qa"]}>
      <ReactDatePicker {...props} />
    </Container>
  )
}

DatePicker.defaultProps = {
  dateFormat: "dd MMMM yyyy",
  withIcon: false
}

export default DatePicker
