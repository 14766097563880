import React from "react"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import { Container, Event } from "./styles"

const ImportantDates = ({ largerFont, strikeout, campaignData }) => {
  const {
    friendlyInviteTime: inviteTime,
    inviteTimeElapsed,
    friendlyInviteReminderTime: inviteReminderTime,
    inviteReminderTimeElapsed,
    friendlyActivationDate: activationDate,
    activationDateElapsed,
    friendlyLastDayStandardQuestions: lastDayStandardQuestions,
    lastDayStandardQuestionsElapsed,
    hasPrimers,
    friendlyPostCoursePrimersAvailableEmailTriggerTime: primersEmailTime,
    postCoursePrimersAvailableEmailTriggerTimeElapsed: primersEmailTimeElapsed,
    friendlyCompletionDate: completionDate,
    completionDateElapsed,
    friendlyCompletionEmailSentTime: completionEmailSentTime,
    completionEmailSentElapsed
  } = campaignData

  const isElapsed = timeElapsed => {
    return strikeout ? timeElapsed : false
  }

  return (
    <Container largerFont={largerFont}>
      <Box paddingTop="xsmall" paddingBottom="xsmall">
        {!!inviteTime && (
          <Event
            color="quaternary"
            paddingBottom
            elapsed={isElapsed(inviteTimeElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                {inviteTime}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Invite emails sent
              </Text>
            </Box>
          </Event>
        )}
        {!!inviteReminderTime && (
          <Event
            color="quaternary"
            elapsed={isElapsed(inviteReminderTimeElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                {inviteReminderTime}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Invite reminders sent
              </Text>
            </Box>
          </Event>
        )}
      </Box>
      <Box paddingTop="xsmall" paddingBottom="xsmall">
        {!!activationDate && (
          <Event
            color="primary"
            paddingBottom={!!lastDayStandardQuestions}
            elapsed={isElapsed(activationDateElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                {activationDate}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Campaign start date
              </Text>
            </Box>
          </Event>
        )}
        {!!lastDayStandardQuestions && (
          <Event
            color="primary"
            paddingTop={!!activationDate}
            elapsed={isElapsed(lastDayStandardQuestionsElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                {lastDayStandardQuestions}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Last day standard questions
              </Text>
            </Box>
          </Event>
        )}
      </Box>
      {hasPrimers && !!primersEmailTime && (
        <Box paddingTop="xsmall" paddingBottom="xsmall">
          <Event color="quinary" elapsed={isElapsed(primersEmailTimeElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                {primersEmailTime}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Campaign cooldown 'primer' email
              </Text>
            </Box>
          </Event>
        </Box>
      )}
      <Box paddingTop="xsmall" paddingBottom="xsmall">
        {!!completionDate && (
          <Event
            paddingBottom={!!completionEmailSentTime}
            elapsed={isElapsed(completionDateElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                11:59pm - {completionDate.slice(0, -5)}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Campaign completed
              </Text>
            </Box>
          </Event>
        )}
        {!!completionEmailSentTime && (
          <Event
            paddingTop={!!completionDate}
            elapsed={isElapsed(completionEmailSentElapsed)}>
            <Box paddingRight="medium">
              <Text size="small" align="right">
                {completionEmailSentTime}
              </Text>
            </Box>
            <Box paddingLeft="medium">
              <Text size="small" align="left">
                Feedback reminder emails sent
              </Text>
            </Box>
          </Event>
        )}
      </Box>
    </Container>
  )
}

export default ImportantDates
